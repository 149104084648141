<template>
  <div>
    <!-- {{ masterBiodata }} -->
    <!-- {{masterPermohonan}} -->
    <!-- collapse -->
    <app-collapse id="daftar_dokumen" accordion type="margin" class="mt-0">
      <div v-if="loading" class="text-center">
        <b-spinner variant="primary" label="Text Centered" />
      </div>
      <app-collapse-item
        v-for="(dok, index) in localDaftarBuktiBayar"
        :key="index"
        :title="dok.id"
        :is-visible="true"
      >
        <template #header>
          <b-container fluid class="w-100">
            <b-row>
              <b-col cols="9" class="text-left">
                <span class="lead collapse-title">Bukti Bayar</span>
              </b-col>
              <b-col cols="3" class="text-right">
                <!-- <b-badge pill variant="success">Terverifikasi</b-badge> -->
                <verification-status :status="dok.verification_status" />
              </b-col>
            </b-row>
          </b-container>
        </template>
        <!-- {{dok}} -->
        <div class="p-1">
          <b-row>
            <b-col sm="12">
              <b-form-group label="Tanggal Pembayaran" label-for="tanggal">
                <flat-pickr
                  id="tanggal"
                  v-model="dok.created_at"
                  class="form-control"
                  placeholder="Tanggal Pembayaran"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="File Bukti Bayar"
                label-for="file-bukti-bayar"
              >
                <b-button
                  v-if="dok.file"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="mb-1"
                  @click="download(dok.file)"
                >
                  <feather-icon icon="DownloadCloudIcon" class="mr-50" />
                  <span class="align-middle">Download</span>
                </b-button>
                ({{ dok.file }})
                <b-form-file v-model="dok.file_fisik" plain />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- <form-dokumen :masterDokumen="dok" /> -->
        <template #footer>
          <b-container fluid class="w-100">
            <b-row>
              <b-col cols="9" class="text-left">
                <b-button
                  v-if="showSimpan(dok)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="simpan(index)"
                >
                  Simpan
                </b-button>
                <b-button
                  v-if="showVerifikasi()"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="verifikasiDokumen(index)"
                >
                  Verifikasi
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-info"
                  class="mr-1"
                >
                  Lihat Log
                </b-button>
              </b-col>
              <b-col cols="3" class="text-right">
                <b-button
                  v-if="showHapus(dok)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="ml-1"
                  @click="confirmDelete(index)"
                >
                  Hapus
                </b-button>
              </b-col>
            </b-row>
          </b-container>
          <b-overlay :show="showOverlay" no-wrap>
            <template #overlay>
              <div v-if="isProcessing" class="text-center">
                <b-spinner variant="primary" label="Text Centered" />
              </div>
              <div
                v-else
                ref="dialog"
                tabindex="-1"
                role="dialog"
                aria-modal="false"
                aria-labelledby="form-confirm-label"
                class="text-center p-3"
              >
                <p><strong id="form-confirm-label">Are you sure?</strong></p>
                <div class="d-flex">
                  <b-button
                    variant="outline-danger"
                    class="mr-3"
                    @click="onOverlayCancel"
                  >
                    Cancel
                  </b-button>
                  <b-button variant="outline-success" @click="onOverlayOk">
                    OK
                  </b-button>
                </div>
              </div>
            </template>
          </b-overlay>
        </template>
      </app-collapse-item>
    </app-collapse>
    <!--/ collapse -->
    <div v-if="allowAddNew" class="mt-3">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="tambahDokumenBaru"
      >
        Tambah Bukti Bayar
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BContainer,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BSpinner,
  BFormGroup,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { api, upload, download } from '@/hap-sia/setup'
import emitter from '@/hap-sia/emitter'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from './AppCollapse.vue'
import AppCollapseItem from './AppCollapseItem.vue'
import VerificationStatus from './VerificationStatus.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BContainer,
    BRow,
    BCol,
    // BBadge,
    BButton,
    // BFormSelect,
    BOverlay,
    BSpinner,
    // FormDokumen,
    VerificationStatus,
    // FormFoto,
    flatPickr,
    BFormGroup,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  props: ['masterPermohonan', 'masterBiodata'],
  data() {
    return {
      localDaftarBuktiBayar: [],
      showOverlay: false,
      isProcessing: false,
      indexToBeDeleted: null,
      allowAddNew: true,
      disableDelete: false,
      loading: true,
    }
  },
  async created() {
    try {
      const results = await api().get(
        `permohonan/${this.masterPermohonan.id}/bukti-bayar`,
      )
      this.localDaftarBuktiBayar = results.data
      this.loading = false
      if (this.localDaftarBuktiBayar.length === 0) {
        this.tambahDokumenBaru()
      }
    } catch (error) {
      this.makeToast('danger', 'Error', error.response.data.message, true)
    }
  },
  async mounted() {
    // if (this.config) {
    //   if (this.config.initWithJenisDokumen) {
    //     this.selectedJenisDokumen = this.config.initWithJenisDokumen
    //     if (this.masterDaftarDokumen.length === 0) {
    //       this.tambahDokumenBaru()
    //     }
    //     this.optionsJenisDokumen = this.optionsJenisDokumen.filter(item => (item.value === this.config.initWithJenisDokumen))
    //   }
    //   if (this.config.selectedTipe) {
    //     this.optionsJenisDokumen = [
    //       { value: null, text: '-- Pilih Jenis Dokumen --' },
    //     ]
    //     this.masterJenisDokumen.forEach(element => {
    //       // console.log('JENIS DOK')
    //       // console.log(this.masterJenisDokumen)
    //       if (element.tipe === this.config.selectedTipe) {
    //         this.optionsJenisDokumen.push({
    //           value: element.id,
    //           text: element.nama,
    //         })
    //       }
    //     })
    //   }
    //   if (this.config.allowAddNew != null) {
    //     this.allowAddNew = this.config.allowAddNew
    //   }
    //   if (this.config.disableDelete != null) {
    //     this.disableDelete = this.config.disableDelete
    //   }
    // }
  },
  methods: {
    async simpan(index) {
      console.log('DOKUMEN TO BE SAVED')
      console.log(this.localDaftarBuktiBayar[index])
      const dok = this.localDaftarBuktiBayar[index]

      this.showOverlay = true
      this.isProcessing = true

      if (!dok.permohonan_id) {
        alert('Error: Belum ada Permohonan ID')
        this.showOverlay = false
        this.isProcessing = false
        return
      }

      if (!dok.id && !dok.file_fisik) {
        this.makeToast('danger', 'Error', 'File belum dipilih', true)
        this.showOverlay = false
        this.isProcessing = false
        return
      }
      // upload dulu files
      let filenameUpload
      if (dok.file_fisik) {
        try {
          const formData = new FormData()
          formData.append('file', dok.file_fisik)
          const result = await upload().post(
            `biodata/${this.masterBiodata.id}/upload`,
            formData,
          )
          filenameUpload = result.data.filename
          dok.file = filenameUpload
        } catch (error) {
          alert(error)
          this.showOverlay = false
          this.isProcessing = false
          return
        }
      }

      // lanjut simpan metadata
      if (dok.id) {
        // patch
        try {
          const cloneDok = JSON.parse(JSON.stringify(dok))
          delete cloneDok.file_fisik
          delete cloneDok.verified_at
          delete cloneDok.verified_by
          delete cloneDok.created_by
          delete cloneDok.updated_at
          delete cloneDok.updated_by
          await api().patch('permohonan/bukti-bayar', cloneDok)
        } catch (error) {
          this.makeToast('danger', 'Error', error.response.data.message, true)
          this.showOverlay = false
          this.isProcessing = false
          return
        }
      } else {
        // post
        try {
          const cloneDok = JSON.parse(JSON.stringify(dok))
          delete cloneDok.file_fisik
          cloneDok.verification_status = 0
          const result = await api().post('permohonan/bukti-bayar', cloneDok)
          dok.id = result.data.id
          dok.file_fisik = null
          dok.verification_status = result.data.verification_status
        } catch (error) {
          // alert(`Error: ${error.response.data.message}`)
          this.makeToast('danger', 'Error', error.response.data.message, true)
          this.showOverlay = false
          this.isProcessing = false
          return
        }
      }
      // emit berhasil disimpan
      emitter.emit('BUKTI_BAYAR_TERSIMPAN', dok)

      this.showOverlay = false
      this.isProcessing = false
      this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
    },
    tambahDokumenBaru() {
      if (!this.masterPermohonan.id) {
        alert(
          'Permohonan ID belum ada. Tidak dapat menambahkan Bukti Bayar baru',
        )
        return
      }

      const newDok = {
        permohonan_id: this.masterPermohonan.id,
        verification_status: null,
        created_at: null,
        file: null,
        file_fisik: null,
      }
      // this.masterDaftarDokumen.splice(this.masterDaftarDokumen.length, 0, newDok)
      this.localDaftarBuktiBayar.push(newDok)
    },
    confirmDelete(index) {
      this.showOverlay = true
      this.indexToBeDeleted = index
    },
    onOverlayCancel() {
      this.showOverlay = false
      this.indexToBeDeleted = null
    },
    onOverlayOk() {
      this.isProcessing = true
      this.deleteDokumen()
    },
    async deleteDokumen() {
      this.showOverlay = true
      this.isProcessing = true
      const dok = this.localDaftarBuktiBayar[this.indexToBeDeleted]

      // await new Promise(r => setTimeout(r, 2000))
      if (dok.id) {
        // delete from db
        try {
          await api().delete(`permohonan/bukti-bayar/${dok.id}`)
        } catch (error) {
          this.makeToast('danger', 'Error', error.response.data.message, true)
          this.showOverlay = false
          this.isProcessing = false
          return
        }
      }

      this.localDaftarBuktiBayar.splice(this.indexToBeDeleted, 1)
      this.showOverlay = false
      this.indexToBeDeleted = null
      this.isProcessing = false

      // emit berhasil dihapus
      emitter.emit('BUKTI_BAYAR_DELETED', dok)

      if (this.localDaftarBuktiBayar.length === 0) {
        await new Promise(r => setTimeout(r, 100))
        this.tambahDokumenBaru()
      }
    },
    verifikasiDokumen() {
      // console.log('EMITTING VERIFIKASI')
      // const dok = this.localDaftarBuktiBayar[index]
      // // mungkin nggak perlu emit... langsung show sidebar
      // emitter.emit('VERIFIKASI', dok)
      alert('NOT IMPLEMENTED YET')
    },
    showSimpan(dok) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (
        userData.default_role === 'user' ||
        userData.default_role === 'member'
      ) {
        if (dok.verification_status === '1') {
          return false
        }
        // if (dok.biodata_id !== userData.biodata_id) {
        //   return false
        // }
      }
      if (userData.default_role === 'operator') {
        if (dok.verification_status === '1') {
          return false
        }
        if (this.masterBiodata.dpn_dpc_id !== userData.dpn_dpc_id) {
          return false
        }
      }
      // if (userData.default_role !== 'admin' || userData.default_role !== 'verifikator' || userData.default_role !== 'superadmin') {
      //   return false
      // }
      return true
    },
    showVerifikasi() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (
        userData.default_role !== 'verifikator' &&
        userData.default_role !== 'admin' &&
        userData.default_role !== 'superadmin'
      ) {
        return false
      }
      return true
    },
    showLogVerifikasi() {},
    showHapus(dok) {
      if (this.disableDelete) {
        return false
      }
      return this.showSimpan(dok)
    },
    // download(filename) {
    //   const fileUrl = `${fileServerUrl}/biodata/${this.masterBiodata.id}/${filename}`
    //   window.open(fileUrl)
    // },
    async download(filename) {
      try {
        const result = await download().get(
          `biodata/download/${this.masterBiodata.id}/${filename}`,
        )
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>
