<template>
  <div v-if="daftarLogPermohonan.length > 0">
    <!-- {{ daftarLogPermohonan }} -->
    <b-card title="Status Permohonan" class="mb-2">
      <!-- <b-card-text>
        Persyaratan Permohonan Keanggotaan.
      </b-card-text> -->

      <b-form-group />
      <div v-if="processing" class="d-flex justify-content-center m-2">
        <b-spinner label="Loading..." />
      </div>
      <app-timeline v-else>
        <app-timeline-item
          v-for="(log, index) in daftarLogPermohonan"
          :key="index"
          :title="log.status_permohonan.nama"
          :subtitle="`${log.catatan} (${log.created_by})`"
          :time="getDate(log.created_at)"
          variant="success"
        />
        <!-- <app-timeline-item
          title="Last milestone remain"
          subtitle=""
          time="3 minutes ago"
          variant="info"
        /> -->
      </app-timeline>
      <b-button
        v-if="showReload"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-75"
        block
        @click="recheckLogPermohonan"
      >
        Reload Status
      </b-button>
    </b-card>
  </div>
</template>
<script>
import emitter from '@/hap-sia/emitter'
import {
  BCard,
  BButton,
  //   BCardText,
  BFormGroup,
  //   BFormCheckboxGroup,
  BSpinner,
} from 'bootstrap-vue'
import { api } from '@/hap-sia/setup'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    // BCardText,
    BFormGroup,
    // BFormCheckboxGroup,
    BButton,
    BSpinner,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  // props: ['masterPermohonan', 'jenisPermohonanId', 'masterDaftarPersyaratan', 'masterDaftarDokumen'],
  props: ['masterBiodata', 'masterPermohonan'],
  data() {
    return {
      daftarLogPermohonan: [],
      showReload: false,
      processing: true,
    }
  },
  created() {
    emitter.off('STATUS_PERMOHONAN_UPDATED', this.recheckLogPermohonan)
  },
  async mounted() {
    this.recheckLogPermohonan()

    emitter.on('STATUS_PERMOHONAN_UPDATED', this.recheckLogPermohonan)
  },
  methods: {
    async recheckLogPermohonan() {
      this.processing = true
      this.showReload = false

      try {
        const result = await api().get(
          `permohonan/log/${this.masterPermohonan.id}`,
        )
        this.daftarLogPermohonan = result.data
        this.processing = false
      } catch (error) {
        alert(error)
        this.processing = false
        this.showReload = true
      }
    },
    getDate(theDate) {
      moment.locale('id')
      if (!theDate) {
        return ''
      }
      return moment(theDate).format('LL')
    },
  },
}
</script>
