<template>
  <div>
    <b-sidebar
      ref="refSidebarEl"
      v-model="sbShow"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{ title }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-form-group label="Catatan Perbaikan" label-for="forCatatan">
            <b-form-textarea
              v-model="catatan"
              placeholder="Catatan Perbaikan"
              rows="5"
              trim
            />
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="confirmSave"
            >
              Save
            </b-button>
          </div>
        </b-form>
        <b-overlay :show="showConfirm" no-wrap>
          <template #overlay>
            <div v-if="processing" class="text-center">
              <b-spinner variant="primary" label="Text Centered" />
            </div>
            <div
              v-else
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3"
            >
              <p><strong id="form-confirm-label">Are you sure?</strong></p>
              <div class="d-flex">
                <b-button
                  variant="outline-danger"
                  class="mr-3"
                  @click="onCancel"
                >
                  Cancel
                </b-button>
                <b-button variant="outline-success" @click="onOK">
                  OK
                </b-button>
              </div>
            </div>
          </template>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormTextarea,
  BButton,
  BOverlay,
  BSpinner,
  //   BIcon,
} from 'bootstrap-vue'
import emitter from '@/hap-sia/emitter'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
// import { ref, getCurrentInstance } from '@vue/composition-api'
import { api } from '@/hap-sia/setup'

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    BOverlay,
    BSpinner,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  setup(props, context) {
    const refSidebarEl = ref(null)
    const sbShow = ref(false)
    const showConfirm = ref(false)
    const processing = ref(false)
    const title = ref('Update Status Perbaikan')
    const catatan = ref(null)
    // const cacheApi = null
    let localPermohonan = null

    let state = 'loading'
    let jenisPermohonanID = null

    async function handleOnPermohonanRequestUpdateStatusPermohonan(e) {
      sbShow.value = true
      showConfirm.value = false
      processing.value = false
      jenisPermohonanID = e.masterPermohonan.jenis_permohonan_id
      localPermohonan = e.masterPermohonan

      catatan.value = null

      console.log('Request update status permohonan received')

      state = 'doneLoading'
    }
    const test = ''
    function registerEvent() {
      emitter.on(
        'PERMOHONAN_REQUEST_UPDATE_STATUS_PERBAIKAN',
        handleOnPermohonanRequestUpdateStatusPermohonan,
      )
    }
    function unRegisterEvent() {
      emitter.off(
        'PERMOHONAN_REQUEST_UPDATE_STATUS_PERBAIKAN',
        handleOnPermohonanRequestUpdateStatusPermohonan,
      )
    }

    function makeToast(variant = null, theTitle, body, noAutoHide = false) {
      context.root.$bvToast.toast(body, {
        theTitle,
        variant,
        solid: true,
        autoHideDelay: 2000,
        noAutoHide,
      })
    }

    function confirmSave() {
      showConfirm.value = true
      processing.value = false
    }
    function onCancel() {
      showConfirm.value = false
      processing.value = false
      sbShow.value = false
    }
    async function onOK() {
      showConfirm.value = true
      processing.value = true
      try {
        const result = await api().patch('permohonan/status', {
          id: localPermohonan.id,
          status_approval_id: 2,
          log_permohonan: {
            status_id: 13,
            catatan: catatan.value,
          },
        })
        emitter.emit('STATUS_PERMOHONAN_UPDATED', result.data)
      } catch (error) {
        alert(error)
        makeToast('danger', 'Error', error.response.data.message, true)
      }
      showConfirm.value = false
      processing.value = false
      sbShow.value = false
      makeToast('success', 'Sukses', 'Data berhasil disimpan')
    }

    unRegisterEvent()
    registerEvent()

    return {
      refSidebarEl,
      test,
      sbShow,
      showConfirm,
      processing,
      title,
      confirmSave,
      onCancel,
      onOK,
      catatan,
      state,
      jenisPermohonanID,
      localPermohonan,
    }
  },
}
</script>
