<template>
  <div class="position-relative">
    <b-overlay :show="processing" no-wrap />
    <!-- BIODATA ID:
    <br>
    {{masterBiodata}}
    <br> -->
    <!-- PERMOHONAN:
    <br>
    {{masterPermohonan}} -->
    <!-- Action Buttons -->
    <b-card v-if="showCard">
      <b-button
        v-if="showButtons.ajukanPerbaikan"
        :disabled="userData.default_role != 'member'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="mb-75"
        block
        @click="updateStatusPerbaikan"
      >
        Pengajuan Perbaikan
      </b-button>
      <b-button
        v-if="
          (masterPermohonan.status_approval_id == 2 &&
            userData.default_role == 'verifikator') ||
            (masterPermohonan.status_approval_id == 2 &&
              userData.default_role == 'admin') ||
            (masterPermohonan.status_approval_id == 2 &&
              userData.default_role == 'superadmin') ||
            (masterPermohonan.status_approval_id == 2 &&
              userData.default_role == 'operator')
        "
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-75"
        block
        @click="confirmApprove"
      >
        Approve
      </b-button>

      <b-button
        v-if="masterPermohonan.is_draft"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-75"
        block
        @click="confirmAjukanPermohonan"
      >
        Ajukan Permohonan
      </b-button>

      <b-button
        v-if="
          (showButtons.approve && userData.default_role == 'verifikator') ||
            (showButtons.approve && userData.default_role == 'admin') ||
            (showButtons.approve && userData.default_role == 'superadmin') ||
            (showButtons.approve && userData.default_role == 'operator')
        "
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-75"
        block
        @click="confirmApprove"
      >
        Approve
      </b-button>

      <b-button
        v-if="showButtons.mintaPerbaikan"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-75"
        block
        @click="updateStatusPerbaikan"
      >
        Perbaikan
      </b-button>

      <b-button
        v-if="showButtons.tanggalAkhir"
        :disabled="
          userData.default_role == 'member' || userData.default_role == 'user'
        "
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-75"
        block
        @click="updateTanggalAkhir"
      >
        Ubah Tanggal<br />Berakhir Keanggotaan
      </b-button>

      <b-button
        v-if="showButtons.updateStatus"
        :disabled="
          userData.default_role == 'member' || userData.default_role == 'user'
        "
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-75"
        block
        @click="updateStatusPermohonan"
      >
        Update Status
      </b-button>
      <b-button
        v-if="showButtons.nia"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-75"
        block
      >
        Tetapkan NIA
      </b-button>
      <b-button
        v-if="showButtons.updateStatus"
        :disabled="
          userData.default_role == 'member' || userData.default_role == 'user'
        "
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="mb-75"
        block
        @click="confirmSelesai"
      >
        Selesai
      </b-button>
      <!-- <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-75"
        block
        @click="toggleSidebarBAS"
      >
        Unggah BAS
      </b-button> -->
    </b-card>
    <sidebar-permohonan-tanggal-akhir :master-biodata="masterBiodata" />
  </div>
</template>
<script>
import emitter from '@/hap-sia/emitter'
import { BCard, BButton, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { api } from '@/hap-sia/setup'
import SidebarPermohonanTanggalAkhir from '@/views/hap/shared/SidebarPermohonanTanggalAkhir.vue'

export default {
  components: {
    BCard,
    BButton,
    BOverlay,
    SidebarPermohonanTanggalAkhir,
  },
  directives: {
    Ripple,
  },
  props: ['masterPermohonan', 'masterBiodata', 'jenisPermohonanID'],
  data() {
    return {
      showButtons: {
        ajukanPermohonan: false,
        ajukanPerbaikan: false,
        approve: false,
        mintaPerbaikan: false,
        updateStatus: false,
        nia: false,
        tanggalAkhir: false,
      },
      showCard: true,
      processing: false,
      userData: null,
    }
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    if (!this.masterPermohonan) {
      this.showButtons.ajukanPermohonan = true
    }
    this.showActions()
  },
  methods: {
    showActionButtons() {
      // test
    },
    async ajukanPermohonan() {
      if (this.masterPermohonan) {
        await api().patch('permohonan', {
          id: this.masterPermohonan.id,
          biodata_id: this.masterPermohonan.biodata_id,
          jenis_permohonan_id: this.masterPermohonan.jenis_permohonan_id,
          is_draft: false,
          log_permohonan: {
            status_id: 1,
            catatan: 'Permohonan diajukan',
          },
        })
        // this.masterPermohonan.is_draft = false
      } else {
        await api().post('permohonan', {
          biodata_id: this.masterBiodata.id,
          jenis_permohonan_id: this.jenisPermohonanID,
          is_draft: false,
        })
        // this.masterPermohonan = result.data
      }
      // try {
      //   const result = await api.post('permohonan', {
      //     biodata_id: this.masterBiodata.id,
      //     jenis_permohonan_id: this.jenisPermohonanID,
      //     is_draft: false,
      //   })
      // } catch (error) {
      //   alert(error.response.data.message)
      // }
    },
    async approve() {
      const result = await api().patch('permohonan/status', {
        id: this.masterPermohonan.id,
        status_approval_id: 1,
        log_permohonan: {
          status_id: 2,
          catatan: 'Permohonan disetujui',
        },
      })
      this.masterPermohonan.status_approval_id = result.data.status_approval_id
      emitter.emit('STATUS_PERMOHONAN_UPDATED', result.data)
    },
    async selesai() {
      const result = await api().patch('permohonan/status', {
        id: this.masterPermohonan.id,
        is_fulfilled: true,
        log_permohonan: {
          status_id: 99,
          catatan: 'Permohonan telah selesai',
        },
      })
      // alert('here')
      this.masterPermohonan.is_fulfilled = true
      emitter.emit('STATUS_PERMOHONAN_UPDATED', result.data)
    },
    confirmAjukanPermohonan() {
      // this.boxOne = ''
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
        })
        .then(async value => {
          if (value) {
            try {
              this.processing = true
              await this.ajukanPermohonan()
            } catch (error) {
              // alert(error.response.data.message)
              this.makeToast(
                'danger',
                'Error',
                error.response.data.message,
                true,
              )
              this.processing = false
              return
            }
            this.processing = false
            this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
            this.$router.push({ name: 'permohonan-terima-kasih' })
          }
        })
    },
    confirmApprove() {
      // this.boxOne = ''
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
        })
        .then(async value => {
          if (value) {
            try {
              this.processing = true
              await this.approve()
            } catch (error) {
              this.makeToast(
                'danger',
                'Error',
                error.response.data.message,
                true,
              )
              this.processing = false
              return
            }
            this.processing = false
            this.makeToast('success', 'Sukses', 'Permohonan disetujui')
            this.showActions()
          }
        })
    },
    confirmSelesai() {
      // this.boxOne = ''
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
        })
        .then(async value => {
          if (value) {
            try {
              this.processing = true
              await this.selesai()
              // await new Promise(r => setTimeout(r, 2000))
            } catch (error) {
              this.makeToast(
                'danger',
                'Error',
                error.response.data.message,
                true,
              )
              this.processing = false
              return
            }
            this.processing = false
            this.makeToast('success', 'Sukses', 'Permohonan selesai')
            this.showActions()
          }
        })
    },
    updateTanggalAkhir() {
      emitter.emit('PERMOHONAN_UPDATE_TANGGAL_AKHIR', {
        masterBiodata: this.masterBiodata,
        masterPermohonan: this.masterPermohonan,
      })
    },
    updateStatusPermohonan() {
      emitter.emit('PERMOHONAN_REQUEST_UPDATE_STATUS_PERMOHONAN', {
        masterBiodata: this.masterBiodata,
        masterPermohonan: this.masterPermohonan,
      })
    },
    updateStatusPerbaikan() {
      emitter.emit('PERMOHONAN_REQUEST_UPDATE_STATUS_PERBAIKAN', {
        masterBiodata: this.masterBiodata,
        masterPermohonan: this.masterPermohonan,
      })
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 2000,
        noAutoHide,
      })
    },
    showAjukanPermohonan() {
      if (this.masterPermohonan) {
        this.showButtons.ajukanPermohonan = false
      }
      this.showButtons.ajukanPermohonan = true
    },
    showActions() {
      this.showButtons.approve =
        this.masterPermohonan.status_approval_id === '0' &&
        !this.masterPermohonan.is_draft
      this.showButtons.nia =
        this.masterPermohonan.status_approval_id === '1' &&
        this.masterPermohonan.jenis_permohonan_id !== '3'
      this.showButtons.ajukanPerbaikan =
        this.masterPermohonan.status_approval_id === '2'
      this.showButtons.mintaPerbaikan =
        this.masterPermohonan.status_approval_id === '0' &&
        !this.masterPermohonan.is_draft
      this.showButtons.updateStatus =
        this.masterPermohonan.status_approval_id === '1'
      this.showButtons.tanggalAkhir =
        this.masterPermohonan.status_approval_id === '1'
    },
  },
}
</script>
